@import "./mixins.scss";
.block_heading {
  font-size: 5rem;
  line-height: 1.5;

  @include sm {
    margin-bottom: 1.5rem;
    font-size: 2.2rem;
  }
}

.section_read-more {
  color: $color--base-black;
  font-size: 1.3rem;
  font-weight: 700;

  text-decoration: none;
  text-align: center;
  display: block;
  width: 100%;

  &:hover {
    font-size: 1.4rem;
    text-shadow: 5px 5px 1px #dfdfdf;
    transition: all 200ms ease-in-out;
  }
}
