@import "../root.scss";

#hero {
  @include block_container;
  gap: 1rem;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10vh auto 20vh;
  max-width: 100%;

  @include lg {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 10vh auto;
  }
}

.hero-navBar {
  @include row;
  grid-row: 1 / 2;
  grid-column: 1/ 2;
  justify-content: space-between;

  a {
    text-decoration: none;
    font-weight: 600;
    height: fit-content;
    padding: 0.7rem;

    font-size: 0.8rem;

    @include md {
      font-size: 1rem;
    }

    &:first-of-type {
      border: solid $color--base-black 2px;
      border-radius: 0.3rem;
    }
  }
}

.hero-textbox {
  @include column;
  justify-content: center;
  grid-column: 1 / 2;
  grid-row: 3 / 4;

  @include lg {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  h1 {
    font-size: 1.4rem;
    @include md {
      font-size: 1.95rem;
    }
  }
}

.hero-image-container {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  overflow: hidden;
  text-align: center;
  overflow: hidden;

  @include lg {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }

  img {
    position: relative;
    left: -10%;
    top: 0;
    height: 100%;
    z-index: 5;

    @include md {
      left: 0%;
    }

    @include lg {
      left: -30%;
    }
  }
}
