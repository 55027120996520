@import "../root.scss";

#contact {
  @include block_container;
  height: fit-content;
  padding: 2rem 1rem;

  h3 {
    font-size: 4rem;
    margin-bottom: 2rem;
  }

  #contact-container {
    border: solid $color--base-black 2px;
    justify-content: space-between;
    border-radius: 0.3rem;
    padding: 3rem;
    @include column;
    height: fit-content;

    @include lg {
      @include row;
    }

    #contact-badges {
      display: flex;
      flex-direction: row !important;
      justify-content: space-between;

      @include lg {
        display: flex;
        flex-direction: column !important;
      }

      .contact-badge {
        @include row;
        align-items: center;
        text-decoration: none;
        margin-bottom: 2rem;

        @include lg {
          width: 100%;
        }

        p {
          font-size: 2rem;
          display: none;
          width: 0;
          @include lg {
            display: block;
          }
        }

        svg {
          font-size: 2rem;
          margin-right: 1rem;
        }

        &:not(:last-child) {
          margin-bottom: 2rem;
        }
      }
    }

    form {
      height: 100%;
      justify-content: center;
      width: 100%;

      @include sm {
      }

      textarea {
        height: 100%;
      }

      input[type="submit"] {
        background-color: white;
        font-weight: 500;
        color: $color--base-black;

        &:hover {
          transition: all 200ms ease-in-out;
          background-color: $color--base-black;
          color: white;
        }
      }
      input,
      textarea {
        font-weight: 300;
        border: solid $color--base-black 2px;
        border-radius: 0.3rem;
        font-size: 1.25rem;
        padding: 0.7rem 0.5rem;
      }

      textarea {
        @include lg {
          height: 10rem;
        }
      }

      :not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    #contact-badges,
    form {
      @include column;

      @include lg {
        width: 50%;
      }
    }
  }
}
