@import "../root.scss";

.project-card {
  border: solid $color--base-black 1px;
  height: 40vh;
  border-radius: 0.3rem;

  overflow: hidden;

  &_image-container {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
  .image-overlay {
    height: 0;
    z-index: 5;
    background-color: #e6e6e6;
    transition: all 0.8s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 1.25rem;
    }

    * {
      display: none;
    }
  }
  &:hover {
    .image-overlay {
      padding: 1rem 1.25rem;
      transition: height 1s ease;
      height: calc(100% - 1rem);

      .project-badges {
        margin-top: auto;
        width: 100%;
        display: flex;
      }
      a {
        svg {
          font-size: 1.5rem;
        }
      }

      * {
        display: block;

        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
  }

  &_header {
    width: 100%;
    background-color: rgb(70, 70, 70);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.25rem 1rem;

    .cardButton {
      display: block;
      width: 0.75rem;
      height: 0.75rem;
      margin-left: 1rem;
      border-radius: 50%;

      &-min {
        background-color: green;
      }
      &-max {
        background-color: yellow;
      }
      &-close {
        background-color: red;
      }
    }
  }
}
