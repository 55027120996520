@import "../../root.scss";

#featured-post {
  @include column;
  justify-content: flex-end;

  @include border-thin;
  border-radius: 0.5rem;

  background-color: $color--gray;

  grid-column: 1 / 7;
  grid-row: 1 / 5;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &_info-card {
    @include border-thin;
    width: 80%;
    background-color: whitesmoke;
    padding: 1rem 1rem 1.7rem;

    @include sm {
      width: 100%;
    }

    h3 {
      font-weight: 900;
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
      @include md {
        font-size: 2.5rem;
      }
    }

    hr {
      margin-bottom: 1rem;
      width: 50%;
      border: solid 2px;
    }
  }
}
