@import "../../root.scss";

.side-post {
  grid-column: 7 / 13;
  display: grid;
  grid-template-columns: 20% 80%;
  width: 100%;

  div {
    @include column;
    justify-content: center;
    margin-left: 0.5rem;

    height: 100%;
    padding-right: 1rem;

    h3 {
      margin-bottom: 0.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 1rem;
      font-size: 1.2rem;

      @include md {
        font-size: 1.7rem;
      }
    }

    p {
      margin-left: 1rem;
    }
  }

  span {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    display: block;
    height: fit-content;
    border-right: solid $color--base-black 6px;

    @include md {
      font-size: 2rem;
      width: 5rem;
    }
  }
}
