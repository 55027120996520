@import "../root.scss";

#blog {
  @include block_container;
  @include column;
  justify-content: flex-start;

  @include sm {
    padding: 10%;
  }

  #sample-post_container {
    width: 100%;
    gap: 20px;
    @include column;

    @include lg {
      height: 50vh;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(4, 1fr);
      height: 100%;
    }

    margin-bottom: 2rem;
  }

  &_read-more {
    font-size: 1.7rem;
  }

  a {
    text-decoration: none;
  }
}
