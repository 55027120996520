.calendar {
  position: relative;
  top: -100%;
  width: 100%;
  height: auto;
  border-radius: 10px;
  z-index: -4;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(2rem, 1fr));
  gap: 5px;

  .block {
    height: 2rem;
    width: 100%;
    display: grid;
    place-items: center;
    text-align: center;

    &:hover {
      border: solid rgb(133, 214, 224) 1px;

      .overlay {
        display: block;
      }
    }

    .overlay {
      display: none;
    }

    background-color: rgba(0, 0, 0, 0.5) rgba(110, 240, 179, 0.931);
  }
}
