@import "variables.scss";

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}
// Extra Extra large devices
@mixin xxl {
  @media (min-width: #{$screen-xxl}) {
    @content;
  }
}

@mixin block_container {
  @include sm {
    padding: 1rem;
    max-width: 100%;
  }

  @include md {
    max-width: 100%;
    padding: 2rem 4rem;
  }

  @include lg {
    margin: 0 auto;
    max-width: 1200px;
    padding: 8vh;
  }
}

@mixin row {
  display: flex;
  flex-direction: row;
}

@mixin column {
  display: flex;
  flex-direction: column;
}

@mixin bordered {
  border: solid $color--base-black 2px;
  border-radius: 0.3rem;
}

@mixin border-thin {
  @include bordered;
  border-width: 1px;
}
